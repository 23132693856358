import axiosBase from "./base";

export const loginTeacher = (email, password) => {
    return new Promise((res, rej)=>{
        axiosBase("post", `teacher/login`, {
            email,
            password
        }).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}

export const createClass = (batchId, teacherId, subject, day) => {
    return new Promise((res, rej)=>{
   
        axiosBase("post", `teacher/class`, {
            
            batchId,
            teacherId,
            subject,
            schedule: {
                day  // string
            },
            

        }).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}



export const getTeacherBatches = (teacherId) => {
    return new Promise((res, rej)=>{
        axiosBase("get", `admin/batches`, {
            teacherId
        }).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}

export const classByBatch = (batchId) => {
    return new Promise((res, rej) => {
      axiosBase("get", `teacher/classByBatch?batchId=${batchId}`)
        .then((e) => {
          console.log(e);
          res(e);
        })
        .catch((e) => {
          console.log(e);
          rej(e);
        });
    });
  };
  
export const assignHomework = (batchId, description, googleClassroomLink, date) => {
    return new Promise((res, rej)=>{
    
        axiosBase("post", `teacher/homework`, {
            batchId,
            description,
            googleClassroomLink,
            date //string
        }).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}
export const getHomeworksForSpecificBatch = (batchId) => {
    return new Promise((res, rej)=>{
        axiosBase("get", `teacher/homework/${batchId}`).then((e)=>{
            res(e)
        }).catch((e)=>{
            rej(e)
        })
    })
}
