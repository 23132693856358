import './App.css';
import Header from './components/header';
import { useEffect, useState } from 'react';
import Loading from './components/loading';
import { checkAuth } from './helpers/firebase';
import { message } from 'antd';
import pages from './helpers/pages';
import Footer from './components/footer';

function App() {
  const [currentPage, setCurrentPage] = useState(<Loading/>)
  const [loaded, setLoaded] = useState(false)
  const [authState, setAuthState] = useState(false)

  useEffect((e)=>{
    if(window.sessionStorage.getItem("tid") !== undefined && window.sessionStorage.getItem("tid") !== null && window.sessionStorage.getItem("tid") !== ""){
      setAuthState(true)
    }else{
      setAuthState(false)
    }
   
    const url = window.location.pathname.split("/")
   url.splice(0,1)
   if(loaded === false){

    setCurrentPage(pages([url[0]]))
    setLoaded(true)
   }
  })

  return (
    <div className="App">
      <Header loggedIn={authState} changePage={setCurrentPage}/>
        {currentPage}

      <Footer changePage={setCurrentPage}/>

      </div>
  );
}

export default App;
