import React, { useState, useEffect } from "react";
import { getTeacherBatches, createClass, assignHomework, getHomeworksForSpecificBatch, classByBatch } from "../../helpers/requests/teacher.js";

const Dashboard = () => {
  const [teacherId, setTeacherId] = useState(""); // Replace with dynamic value if needed
  const [teacherName, setTeacherName] = useState(""); // Replace with dynamic value if needed
  const [batches, setBatches] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedBatchID, setSelectedBatchID] = useState(null);
  const [subject, setSubject] = useState("");
  const [day, setDay] = useState("");
  const [homeworkDescription, setHomeworkDescription] = useState("");
  const [googleClassroomLink, setGoogleClassroomLink] = useState("");
  const [homeworks, setHomeworks] = useState([]);

  useEffect(() => {

    setTeacherId(window.sessionStorage.getItem("tid"))
    setTeacherName(window.sessionStorage.getItem("tn"))

    // Fetch teacher's batches on component mount
    getTeacherBatches(teacherId)
      .then((response) => {
        setBatches(response); // Assuming the response contains a `data` array
      })
      .catch((error) => console.error("Error fetching batches:", error));
  }, [teacherId]);

  const handleCreateClass = () => {
    if (selectedBatchID && subject && day) {
      createClass(selectedBatchID, teacherId, subject, day)
        .then((response) => {
          alert("Class created successfully!");
          console.log(response);
        })
        .catch((error) => console.error("Error creating class:", error));
    } else {
      alert("Please select a batch, subject, and day.");
    }
  };

  const handleAssignHomework = () => {
    if (selectedBatchID && homeworkDescription && googleClassroomLink) {
      assignHomework(selectedBatchID, homeworkDescription, googleClassroomLink, new Date().toISOString())
        .then((response) => {
          alert("Homework assigned successfully!");
          console.log(response);
        })
        .catch((error) => console.error("Error assigning homework:", error));
    } else {
      alert("Please fill out all homework details.");
    }
  };

  const fetchHomeworks = (id) => {

      getHomeworksForSpecificBatch(id)
        .then((response) => {
          setHomeworks(response); // Assuming response contains `data` array
        })
        .catch((error) => console.error("Error fetching homeworks:", error));
   
  };

  const handleGBG = (batchId) => {

    classByBatch(batchId).then((e)=>{
      setClasses(e)

    })
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Teacher Dashboard</h1>
Logged in as: {teacherName}
      {/* Batches Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {batches.map((batch) => (
          batch.teachers.indexOf(teacherId) > -1 ? <div
          key={batch.id}
          onClick={() => {setSelectedBatch(batch.id); setSelectedBatchID(batch._id);fetchHomeworks(batch._id); handleGBG(batch._id)}}
          className={`p-4 bg-white shadow-md rounded-lg cursor-pointer ${
            selectedBatch === batch.id ? "border-2 border-blue-500" : ""
          }`}
        >
          <h3 className="text-lg font-semibold text-gray-700">{batch.name}</h3>
          <p className="text-sm text-gray-500">Level: {batch.level}</p>
        </div> : <></>
        ))}
      </div>

      {selectedBatch && (
        <div className="mt-6 p-4 bg-blue-100 rounded-md">
          <p className="text-gray-700 font-medium">
            Selected Batch ID: <span className="font-bold">{selectedBatch}</span>
          </p>
        </div>
      )}

      {/* Actions Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
        {/* Create Class Card */}
        <div className="p-4 bg-white shadow-md rounded-lg">
          <h3 className="text-lg font-semibold text-gray-700 mb-4">Create Class</h3>
          <input
            type="text"
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="w-full mb-2 px-3 py-2 border rounded-md"
          />
          <input
            type="text"
            placeholder="Day (e.g., Monday)"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            className="w-full mb-4 px-3 py-2 border rounded-md"
          />
          <button
            onClick={handleCreateClass}
            className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Create Class
          </button>
        </div>

        {/* Assign Homework Card */}
        <div className="p-4 bg-white shadow-md rounded-lg">
          <h3 className="text-lg font-semibold text-gray-700 mb-4">Assign Homework</h3>
          <input
            type="text"
            placeholder="Description"
            value={homeworkDescription}
            onChange={(e) => setHomeworkDescription(e.target.value)}
            className="w-full mb-2 px-3 py-2 border rounded-md"
          />
          <input
            type="text"
            placeholder="Google Classroom Link"
            value={googleClassroomLink}
            onChange={(e) => setGoogleClassroomLink(e.target.value)}
            className="w-full mb-4 px-3 py-2 border rounded-md"
          />
          <button
            onClick={handleAssignHomework}
            className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Assign Homework
          </button>
        </div>
      </div>

      {/* Homeworks Section */}
     <div className="grid grid-cols-2">

     <div className="mt-6 mx-1">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">Batch Classes</h3>
   
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {classes.map((cls, index) => (
            <div key={index} className="p-4 bg-white shadow-md rounded-lg">
              <p className="font-medium text-gray-700">
                <strong>Subject:</strong> {cls.subject}
              </p>
              <p className="text-sm text-gray-500">
                <strong>Date: {cls.schedule.day} </strong>{" "}
                <a
                  // href={homework.googleClassroomLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {/* {homework.googleClassroomLink} */}
                </a>
              </p>
            </div>
          ))}
        </div>
      </div>
     <div className="mt-6 mx-1">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">Batch Homeworks</h3>
      
        <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
          {homeworks.map((homework, index) => (
            <div key={index} className="p-4 bg-white shadow-md rounded-lg">
              <p className="font-medium text-gray-700">
                <strong>Description:</strong> {homework.description}
              </p>
              <p className="text-sm text-gray-500">
                <strong>Link:</strong>{" "}
                <a
                  href={homework.googleClassroomLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {homework.googleClassroomLink}
                </a>
              </p>
            </div>
          ))}
        </div>
      </div>
     </div>
    </div>
  );
};

export default Dashboard;
